import React from 'react'

const SettingsView = ({ heading='Settings' }) => {
    return (
        <div>
            <h1>{heading}</h1>
        </div>
    )
}

export default SettingsView
