
import React from 'react'
import { SettingsSection } from '../../system/settings/pageData'
import Layout from '../../views/templates/adminLayout'
import SettingsView from '../../views/settings/index'

const PageInfo = {
    parent: SettingsSection.section,
    page: 'Menus',
    icon: SettingsSection.icon,
}

const Settings = () => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={SettingsSection.minimumRole}>
            <SettingsView heading='Menu Settings'/>
            <p>Coming Soon...</p>
        </Layout>
    )
}

export default Settings
